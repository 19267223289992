import Breadcrumb from 'components/Breadcrumb';
import { PaginationOwn } from 'components/Shared';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import FilterButton from 'components/UiComponents/FilterButton/FilterButton';
import PanelSiderBar from 'components/UiComponents/PanelSiderBar/PanelSiderBar';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { FORM_FORMAT } from 'constant/form.constant';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { BiDownload } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getTcbReportFilesAsync,
  getUrlTcbReportFilesAsync,
  selectTcbReportFiles,
} from 'store/slices/tcb-report-files/TcbReportFilesSlice';

const TcbReportFilesManagementPage = () => {
  const { handleSubmit, reset } = useForm<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openedFilter, setOpenedFilter] = useState(false);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<any>({});
  const [fromDate, setStart] = useState<any>();
  const [toDate, setEnd] = useState<any>();
  const { tcbReportFiles } = useAppSelector(selectTcbReportFiles);

  const crumbs = [
    {
      name: 'Quản Lý File Đối Soát',
      url: '/tcb-report-files-management',
    },
  ];

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    await dispatch(getTcbReportFilesAsync(params));
    setLoading(false);
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        ...filters,
        page_index: page,
        page_size: itemPerPage,
      };
      getData(params);
    }
  }, [filters, itemPerPage, page]);

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/tcb-report-files-management`);
    setItemPerPage(value);
  };

  const clearFilter = () => {
    reset();
    setStart('');
    setEnd('');
    setFilters({});
    setOpenedFilter(false);
    navigate(`/tcb-report-files-management`);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;
    if (filerValues.announcement_type === '') {
      delete filerValues['announcement_type'];
    }
    if (fromDate) {
      const FromDate = moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        FromDate,
      };
    }
    if (toDate) {
      const ToDate = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        ToDate,
      };
    }

    setOpenedFilter(false);
    navigate(`/tcb-report-files-management`);
    setFilters(filerValues);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/tcb-report-files-management?page=${event}`);
  }, []);

  const handleDownloadTcbReportFile = async (id: any) => {
    const res = await dispatch(getUrlTcbReportFilesAsync(id));
    const link_download = res?.payload?.data?.url;
    const link = document.createElement('a');
    link.href = link_download;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage icon={() => <AiOutlineBook />} name="Quản Lý File Đối Soát" />
      <div className="mt-10">
        <div className="flex">
          <div className="flex  w-1/2 space-x-2">
            <div className="space-x-2">
              <FilterButton toggleFilter={toggleFilter} />
            </div>
          </div>
          <div className="ml-auto mt-2">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th style={{ width: '60px' }}>STT</th>
                <th>
                  <span>Tên File</span>
                </th>
                <th>
                  <span>Ngày tạo</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {tcbReportFiles?.data?.items?.map((item: any, index: number) => (
                  <tr key={item?.transaction_id}>
                    <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                    <td>
                      <span>{item?.file_name || '-'}</span>
                    </td>
                    <td>
                      <span>
                        {item.created_at
                          ? moment(new Date(item.created_at)).format(FORM_FORMAT.TABLE_DATE)
                          : '-'}
                      </span>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <div
                          className="items-center text-base table-action-btn"
                          onClick={() => {
                            handleDownloadTcbReportFile(item?.transaction_id);
                          }}
                        >
                          <BiDownload className="text-primary text-lg" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {tcbReportFiles?.data?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No announcement found</p>
        )}
        {tcbReportFiles?.data?.total_results > itemPerPage && !loading && (
          <div className="my-6 flex text-right">
            <PaginationOwn
              totalItems={tcbReportFiles?.data?.total_results}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
      <PanelSiderBar
        open={openedFilter}
        close={() => {
          setOpenedFilter(false);
        }}
        submit={handleSubmit(submitHandler)}
        clear={clearFilter}
      >
        <form>
          <div className="">
            <label className="text-sm">Ngày đăng</label>
            <div className="flex form-group space-x-2">
              <div className="w-1/2">
                <DatePicker
                  selected={fromDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setStart(date);
                  }}
                  placeholderText="Từ"
                  className="form-control"
                />
              </div>
              <div className="w-1/2">
                <DatePicker
                  selected={toDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setEnd(date);
                  }}
                  placeholderText="Đến"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </PanelSiderBar>
    </>
  );
};

export default TcbReportFilesManagementPage;
