import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMenu } from 'types';
import { NavBarLink } from 'components/NavBarLink';
import { PermissionsGate } from 'components/PermissionsGate';
import { useAuth } from 'contexts/Auth/AuthContext';

export const NavBarItem = ({ item }: { item: IMenu }) => {
  const { submenu } = item;
  const { pathname } = useLocation();
  const subActive = submenu?.find(i => i?.url && pathname.includes(i?.url));
  const isActive = pathname === item?.url;

  const { isShouldBlockNavigate, updateIsShowConfirmSaveData, updateNextUrlCache } = useAuth();
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(!!subActive);

  const handleNavigate = (e: any, url: string) => {
    if (isShouldBlockNavigate) {
      e.preventDefault();
      updateIsShowConfirmSaveData(true);
      updateNextUrlCache(url);
    }
  };

  const handleOnclick = (e: any) => {
    if (submenu) {
      setIsOpenSubmenu(o => !o);
      return;
    }
    if (item?.url) {
      handleNavigate(e, item.url);
    }
  };

  return (
    <PermissionsGate name={item.name}>
      <li className={`menu-item py-2 ${!!subActive || isActive ? 'menu-sub-active' : ''}`}>
        <div className={`flex-1`}>
          <NavBarLink
            {...item}
            subActive={!!subActive}
            isOpenSubmenu={isOpenSubmenu}
            onClick={handleOnclick}
          />
          {submenu ? (
            <div className={`text-sm pl-3 ${isOpenSubmenu ? 'h-auto' : 'h-0 overflow-hidden'}`}>
              <ul className="flex flex-col gap-1 mt-1">
                {submenu.map((i, idx) => {
                  const isMenuActive = i?.url && pathname.includes(i?.url);

                  return (
                    <li
                      key={`submenu-${idx}`}
                      className={`pt-4 ${isMenuActive ? 'menu-item-active' : ''}`}
                    >
                      <NavBarLink {...i} isSubmenu onClick={handleNavigate} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </li>
    </PermissionsGate>
  );
};
