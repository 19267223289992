import Breadcrumb from 'components/Breadcrumb';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { GENERAL_SERVER_ERROR, PERMISSIONS_KEY, SUCCESS } from 'constant';
import { FORM_FORMAT } from 'constant/form.constant';
import { PERMISSIONS_ACTIONS } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineBook } from 'react-icons/ai';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { deleteReportByID, getListReports } from 'services/researchCenter.service';
import { Option, ResponseApiList } from 'types';
import pdfIcon from 'assets/images/pdfIcon.png';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { PaginationOwn } from 'components/Shared';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'components/UiComponents/Modal';
import { getBgStatus, getColorTextStatus, getReportType, REPORT_TYPE_OPTIONS } from 'utils';
import { ListReportParamsType, ReportResearchData } from 'types/data.type';
import { IoMdArrowDropdown } from 'react-icons/io';

const crumbs = [
  {
    name: 'Trung tâm phân tích',
    url: '/report-research-management',
  },
];

const ReportTypeOptions = [
  {
    label: 'Tất cả',
    value: '',
  },
  ...REPORT_TYPE_OPTIONS,
];

const ReportResearchManagement = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [typeReport, setTypeReport] = useState<Option>();
  const [reportList, setReportList] = useState<ResponseApiList<ReportResearchData>>();
  const [itemReport, setItemReport] = useState<ReportResearchData>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const onSearch = (reset?: boolean) => {
    navigate(`/report-research-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const handlePageSizeChange = (value: number) => {
    navigate(`/report-research-management`);
    setItemPerPage(value);
  };

  const getListReport = async (params: ListReportParamsType) => {
    setLoading(true);
    try {
      const res = await getListReports(params);
      if (res) {
        setReportList(res);
      }
    } catch (error) {
      toast.error(GENERAL_SERVER_ERROR);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Number(page)) {
      setCurrentPage(Number(page));
      const params: ListReportParamsType = {
        page_index: Number(page),
        page_size: itemPerPage,
      };

      if (typeReport && typeReport?.value) {
        params.type = typeReport?.value as string;
      }

      if (keyword) {
        params.keyword = keyword;
      }

      getListReport(params);
    }
  }, [typeReport, reloadPage, page, itemPerPage]);

  const onPageChange = (event: number) => {
    navigate(`/report-research-management?page=${event}`);
  };

  const handleDeleteReport = async () => {
    setSubmitDelete(true);
    try {
      const res = await deleteReportByID(itemReport?.id || '');
      if (res?.status === SUCCESS) {
        toast.success('Xóa báo cáo thành công.');
      } else {
        toast.error(res?.error_message?.split('|')[1] || 'Xóa báo cáo thất bại.');
      }
    } catch (error) {
      toast.error('Xóa báo cáo thất bại.');
    }
    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setReloadPage(!reloadPage);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage icon={() => <AiOutlineBook />} name="Trung tâm phân tích" />
        <PermissionsGate
          name={PERMISSIONS_KEY.ManageResearchCenter}
          action={PERMISSIONS_ACTIONS.CREATE}
        >
          <Link to={'/report-research-management/add'}>
            <span className="flex justify-center w-full md:w-52 form-button">Tạo báo cáo mới</span>
          </Link>
        </PermissionsGate>
      </div>
      <div className="mt-10">
        <div className="flex">
          <div className="flex w-1/2 space-x-2 items-end">
            <div className="w-[250px] flex flex-col gap-[5px] m">
              <p className="text-sm font-semibold leading-5">Loại báo cáo</p>
              <div className="relative">
                <Select
                  id="report_type"
                  options={ReportTypeOptions}
                  onChange={option => {
                    setTypeReport(option as Option);
                  }}
                  className="select-style"
                  defaultValue={ReportTypeOptions[0]}
                  classNamePrefix={'report-type-select'}
                />
                <div className="absolute right-[10px] top-[10px]">
                  <IoMdArrowDropdown color="#7D7D7D" size={30} />
                </div>
              </div>
            </div>
            <SearchButton
              onSearch={onSearch}
              keyword={keyword}
              setKeyword={setKeyword}
              _handleKeyDown={_handleKeyDown}
            />
          </div>
          <div className="ml-auto flex items-end">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th style={{ width: '60px' }}>STT</th>
                <th>Tiêu đề</th>
                <th>Ngày đăng</th>
                <th>Loại báo cáo</th>
                <th>Nhà cung cấp</th>
                <th>Tài liệu đính kèm</th>
                <th>Trạng thái</th>
                <th>Reviewed</th>
                <th>Hành động</th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {reportList?.items?.map((item: ReportResearchData, index: number) => (
                  <tr>
                    <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                    <td className="font-semibold max-w-[300px]">
                      <span>{item?.title}</span>
                    </td>
                    <td>
                      <span>
                        {item.published_date
                          ? moment(new Date(item?.published_date.replace('Z', ''))).format(
                              FORM_FORMAT.TABLE_DATEFULLYEAR
                            )
                          : '-'}
                      </span>
                    </td>
                    <td>{getReportType(item?.type)}</td>
                    <td>{item?.provider}</td>
                    <td>
                      {(item?.files?.length || 0) > 0 ? (
                        <a
                          href={item?.files?.[0]?.file_url}
                          className="flex gap-3 items-center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={pdfIcon} alt="PDF Icon" className="w-4 h-4" />
                          <p>{item?.files?.[0]?.display_name}</p>
                        </a>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <div className={`px-2 py-[5px] rounded w-fit ${getBgStatus(item?.status)}`}>
                        <span className={`${getColorTextStatus(item?.status)} `}>
                          {item?.status}
                        </span>
                      </div>
                    </td>
                    <td>
                      {item?.is_reviewed ? (
                        <div className="px-2 py-[5px] rounded w-fit bg-green-50">
                          <span className={`text-green-700`}>Reviewed</span>
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageResearchCenter}
                          action={PERMISSIONS_ACTIONS.UPDATE}
                        >
                          <div className="items-center text-base table-action-btn">
                            <Link to={`/report-research-management/${item?.id}`}>
                              <BiEdit className="text-primary text-lg" />
                            </Link>
                          </div>
                        </PermissionsGate>
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageResearchCenter}
                          action={PERMISSIONS_ACTIONS.DELETE}
                        >
                          <div className="flex gap-3 items-center text-base table-action-btn">
                            <div
                              onClick={() => {
                                setItemReport(item);
                                setConfirmDeleteModal(true);
                              }}
                            >
                              <BiTrash className="text-red-main text-lg" />
                            </div>
                          </div>
                        </PermissionsGate>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {reportList?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No report found</p>
        )}
        {(reportList?.total_results || 0) > itemPerPage && !loading && (
          <div className="my-6 flex text-right">
            <PaginationOwn
              totalItems={reportList?.total_results || 0}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
      <Modal
        show={confirmDeleteModal}
        title={`Xác Nhận Xoá Báo Cáo ${itemReport?.title}`}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={handleDeleteReport}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ReportResearchManagement;
