import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoCalendarClearSharp } from 'react-icons/io5';
import moment from 'moment';
import { UserResearchData } from 'types/data.type';
import { createUser, getUserByID, updateUser } from 'services/researchCenter.service';
import { GENERAL_SERVER_ERROR, SUCCESS } from 'constant';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'components';

const getStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return true;
    case 'DeActive':
      return false;
    default:
      return false;
  }
};

const AddUserManagement = () => {
  const {
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<UserResearchData>({ mode: 'onChange' });
  const paramsURL = useParams();
  const paramsId = paramsURL?.id;
  const navigate = useNavigate();
  const [submitAdd, setSubmitAdd] = useState(false);
  const [status, setStatus] = useState(false);
  const [dateofBirth, setDateofBirth] = useState<Date>();

  const selected = (url: string) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản lý khách hàng',
      url: '/user-research-management',
    },
    {
      name: paramsId ? 'Chỉnh sửa khách hàng' : 'Tạo khách hàng mới',
    },
  ];

  const submitAddHandler: SubmitHandler<UserResearchData> = async values => {
    setSubmitAdd(true);

    const params = {
      ...values,
      status: status ? 'Active' : 'DeActive',
    };

    if (!values?.phone) {
      delete params.phone;
    }

    if (!dateofBirth) {
      delete params.dob;
    } else {
      params.dob = moment(dateofBirth).add(7, 'hours').toISOString();
    }

    if (paramsId) {
      delete params.email;
      try {
        const res = await updateUser(params, paramsId);
        if (res?.status === SUCCESS) {
          toast.success('Cập nhật thông tin thành công');
        } else {
          toast.error(res?.error_message || GENERAL_SERVER_ERROR);
        }
      } catch (error: any) {
        const errors = error?.response?.data;
        errors?.map((item: any) => {
          return toast.error(item?.error_message || GENERAL_SERVER_ERROR);
        });
      }
    } else {
      try {
        const res = await createUser(params);
        if (res?.status === SUCCESS) {
          toast.success('Tạo mới khách hàng thành công');
          navigate(`/user-research-management`);
        } else {
          toast.error(res?.error_message || GENERAL_SERVER_ERROR);
        }
      } catch (error: any) {
        const errors = error?.response?.data;
        errors?.map((item: any) => {
          return toast.error(item?.error_message || GENERAL_SERVER_ERROR);
        });
      }
    }

    setSubmitAdd(false);
  };

  useEffect(() => {
    if (paramsId) {
      const getDetailUser = async () => {
        const res = await getUserByID(paramsId || '');
        if (res?.status === SUCCESS) {
          const data = res?.data;
          setDateofBirth(new Date(data?.dob));
          setStatus(getStatus(data?.status));
          setValue('phone', data?.phone, {
            shouldValidate: true,
          });
          setValue('email', data?.email, {
            shouldValidate: true,
          });
          setValue('full_name', data?.full_name, {
            shouldValidate: true,
          });
          setValue('company', data?.company, {
            shouldValidate: true,
          });
        } else {
          toast.error(res?.error_message?.split('|')[1] || GENERAL_SERVER_ERROR);
        }
      };

      getDetailUser();
    }
  }, [paramsId]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage
          icon={() => <AiOutlineBook />}
          name={`${paramsId ? 'Chỉnh sửa khách hàng' : 'Tạo khách hàng mới'}`}
        />
        <button
          id="submitUpdate"
          type="submit"
          className="flex justify-center w-[106px] form-button"
          disabled={submitAdd}
          onClick={handleSubmit(submitAddHandler)}
        >
          {submitAdd && <CircleSpin />}
          Lưu
        </button>
      </div>
      <div className="mt-[23px] relative">
        <form className="form" noValidate autoComplete="off">
          <ArticleCard className="w-full !p-6">
            <div className="flex flex-col gap-2">
              <div className="flex gap-[13px]">
                <label htmlFor="category_type" className="font-bold">
                  Trạng thái
                </label>
                <Switch
                  onChange={(value: any) => {
                    setStatus(value);
                  }}
                  checked={status}
                  className="react-switch"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={24}
                  width={44}
                  handleDiameter={18}
                  onColor={'#61CB2F'}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div className="lg:flex lg:gap-4">
                <div className="form-group w-1/2">
                  <label htmlFor="email" className="required-after">
                    Email
                  </label>
                  <input
                    id="email"
                    type="text"
                    autoComplete="email"
                    placeholder="Nhập email"
                    className="form-control"
                    {...register('email', {
                      required: 'Vui lòng nhập email',
                    })}
                    disabled={!!paramsId}
                  />
                  <ErrorMessage name="email" errors={errors} />
                </div>
                <div className="form-group w-1/2">
                  <label htmlFor="full_name" className="required-after">
                    Họ và tên
                  </label>
                  <input
                    id="full_name"
                    type="text"
                    autoComplete="full_name"
                    placeholder="Nhập họ và tên"
                    className="form-control"
                    {...register('full_name', {
                      required: 'Vui lòng nhập họ và tên',
                    })}
                  />
                  <ErrorMessage name="full_name" errors={errors} />
                </div>
              </div>
            </div>
            <div className="lg:flex lg:gap-4 lg:mt-[17px]">
              <div className="form-group w-1/2">
                <label htmlFor="dob">Ngày sinh</label>
                <div className="relative">
                  <DatePicker
                    selected={dateofBirth}
                    onChange={(date: any) => {
                      setDateofBirth(date);
                      clearErrors('dob');
                    }}
                    className="form-control z-50"
                    placeholderText="Chọn Ngày sinh"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom"
                  />
                  <div className="absolute right-[14px] top-[9px]">
                    <IoCalendarClearSharp size={20} color={status ? '#898989' : ''} />
                  </div>
                </div>
              </div>
              <div className="form-group w-1/2">
                <label htmlFor="phone">Số điện thoại</label>
                <input
                  id="phone"
                  type="number"
                  autoComplete="phone"
                  placeholder="Nhập số điện thoại"
                  className="form-control"
                  {...register('phone', {
                    required: false,
                  })}
                />
                <ErrorMessage name="phone" errors={errors} />
              </div>
            </div>

            <div className="form-group w-full !mt-[25px]">
              <label htmlFor="company" className="required-after">
                Công ty
              </label>
              <input
                id="company"
                type="text"
                autoComplete="company"
                placeholder="Nhập công ty"
                className="form-control"
                {...register('company', {
                  required: 'Vui lòng nhập công ty',
                })}
              />
              <ErrorMessage name="company" errors={errors} />
            </div>
          </ArticleCard>
        </form>
      </div>
    </>
  );
};

export default AddUserManagement;
