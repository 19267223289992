import React from 'react';
import { useAuth } from 'contexts/Auth/AuthContext';
import cover from 'assets/images/cover-2-lg.png';
import { FaUser } from 'react-icons/fa';

const UserInfor: React.FC = () => {
  const { user } = useAuth();

  return (
    <div
      className={`h-[150px] flex relative w-full p-4 space-x-3 items-center z-10 bg-cover`}
      style={{
        backgroundImage: `url("${cover}")`,
      }}
    >
      {user?.avatar ? (
        <div
          className={'w-12 h-12 rounded-full bg-no-repeat bg-[100%_100%]'}
          style={{
            backgroundImage: `url(" ${user?.avatar}")`,
          }}
        ></div>
      ) : (
        <div
          className={
            'w-12 h-12 rounded-full bg-[#E7EDF4] flex items-center justify-center overflow-hidden'
          }
        >
          <FaUser size={40} color="#7EA0B7" className="-mb-2" />
        </div>
      )}

      <div className="flex-1 text-white">
        <h1 className="text-base font-semibold truncate">{user?.fullname}</h1>
        <p className="text-xs truncate">{user?.position}</p>
        <p className="text-xs truncate">{user?.role}</p>
      </div>
    </div>
  );
};

export default UserInfor;
