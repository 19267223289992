import React, { ReactNode } from 'react';
import { usePermissions, PERMISSIONS_ACTIONS } from 'hooks';
import { PERMISSIONS_KEY } from 'constant';

export const PermissionsGate = ({
  children,
  name,
  action = PERMISSIONS_ACTIONS.VIEW,
  renderError = <></>,
}: {
  children: ReactNode;
  name?: string;
  action?: string;
  renderError?: ReactNode;
}) => {
  const { check } = usePermissions();
  const permissionGranted = name
    ? name === PERMISSIONS_KEY.ManageTransaction || name === PERMISSIONS_KEY.ManageCash
      ? check(PERMISSIONS_KEY.ManageDeposit, action) ||
        check(PERMISSIONS_KEY.ManageWithdrawal, action)
      : check(name, action)
    : true;

  if (!permissionGranted) return <></>;

  return <>{children}</>;
};
