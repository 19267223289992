import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa6';
import { IMenu } from 'types';

type Props = {
  isOpenSubmenu?: boolean;
  isSubmenu?: boolean;
  subActive?: boolean;
  onClick: (e: any, url: string) => void;
};

const menustyle = 'relative flex-1 flex gap-2 items-center text-sm cursor-pointer rounded';
const submenuStyle = 'relative flex-1 flex gap-2 items-center text-sm cursor-pointer rounded';

export const NavBarLink = ({
  title,
  url,
  submenu,
  isOpenSubmenu,
  isSubmenu,
  subActive,
  onClick,
}: IMenu & Props) => {
  const { pathname } = useLocation();
  const isActive = pathname === url;

  return (
    <>
      {submenu || !url ? (
        <div
          className={`${isSubmenu ? submenuStyle : menustyle} ${
            isActive ? 'menu-active' : 'hover:bg-primary-900'
          }`}
          onClick={e => onClick(e, '')}
        >
          <span
            className={`flex-1 ${isActive || subActive ? 'text-text_main_dark' : 'text-white'}`}
          >
            {title}
          </span>
          <span
            className={`text-sm pr-2 ${
              isActive || subActive ? 'text-text_main_dark' : 'text-white'
            }`}
          >
            <FaChevronDown className={`${isOpenSubmenu ? 'rotate-180' : ''}`} />
          </span>
        </div>
      ) : (
        <Link
          to={url}
          onClick={e => onClick(e, url)}
          className={`${isSubmenu ? submenuStyle : menustyle} ${
            isActive ? 'menu-active' : 'hover:bg-primary-900'
          }`}
        >
          <span className={`${isActive ? 'text-text_main_dark' : 'text-white'}`}>{title}</span>
        </Link>
      )}
    </>
  );
};
