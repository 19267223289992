import { RESEARCH_CENTER } from 'constant/api.contant';
import axiosInstance from './restful.service';
import { AddReportParamsType, ListReportParamsType, UserReportParamsType, UserResearchData } from 'types/data.type';

export const getListReports = async (params: ListReportParamsType) => {
  return axiosInstance.get(RESEARCH_CENTER.LIST_REPORT, { params }).then(res => {
    const data = res?.data;
    return data;
  });
};

export const createReport = async (params: AddReportParamsType) => {
  return axiosInstance.post(RESEARCH_CENTER.CREATE_REPORT, params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getReportByID = async (id: string) => {
  return axiosInstance.get(RESEARCH_CENTER.DETAIL_REPORT(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const updateReport = async (params: AddReportParamsType, id: string) => {
  return axiosInstance.put(RESEARCH_CENTER.EDIT_REPORT(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const deleteReportByID = async (id: string) => {
  return axiosInstance.delete(RESEARCH_CENTER.DELETE_REPORT(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getListUsers = async (params: UserReportParamsType) => {
  return axiosInstance.get(RESEARCH_CENTER.LIST_USERS, { params }).then(res => {
    const data = res?.data;
    return data;
  });
};

export const createUser = async (params: UserResearchData) => {
  return axiosInstance.post(RESEARCH_CENTER.CREATE_USER, params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getUserByID = async (id: string) => {
  return axiosInstance.get(RESEARCH_CENTER.DETAIL_USER(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const updateUser = async (params: UserResearchData, id: string) => {
  return axiosInstance.put(RESEARCH_CENTER.EDIT_USER(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const deleteUserByID = async (id: string) => {
  return axiosInstance.delete(RESEARCH_CENTER.DELETE_USER(id)).then(res => {
    const data = res?.data;
    return data;
  });
};
