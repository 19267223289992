import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/hooks';
import {
  createAnnouncementAsync,
  createFileAsync,
  deleteFileAsync,
  getAnnouncementByIdAsync,
  getUrlUploadFilesAsync,
  updateAnnouncementAsync,
  updateFileAsync,
} from 'store/slices/announcement/announcementSlice';

const announcementTypeOptions = [
  {
    value: 'Announcement',
    label: 'Công bố thông tin',
  },
  {
    value: 'FinancialReport',
    label: 'Báo cáo tài chính',
  },
  {
    value: 'CompanyDocument',
    label: 'Tài liệu công ty',
  },
  {
    value: 'DocumentReport',
    label: 'Tài liệu báo cáo',
  },
  {
    value: 'SbbsReport',
    label: 'Thông báo',
  },
  {
    value: 'MarginSecurity',
    label: 'Giao Dịch Ký Quỹ',
  },
];

const AnnouncedInformationAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({});

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [fileData, setFileData] = useState();
  const [showPopupAddFile, setShowPopupAddFile] = useState(false);
  const [showPopupEditFile, setShowPopupEditFile] = useState(false);
  const [showPopupDeleteFile, setShowPopupDeleteFile] = useState(false);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [announcementDetail, setAnnouncementDetail] = useState<any>();
  const [FileDelete, setFileDelete] = useState<any>();
  const [reloadPage, setReloadPage] = useState(false);
  const [isEditFile, setIsEditFile] = useState(false);
  const [announcedInformationID, setAnnouncedInformationID] = useState<any>();
  const [displayName, setDisplayName] = useState<any>();
  const [currentFile, setCurrentFile] = useState<any>();
  const [provider, setProvider] = useState({
    value: '',
    label: '',
  });
  const [announcementType, setAnnouncementType] = useState({
    value: '',
    label: '',
  });

  const navigate = useNavigate();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản Lý Thông Tin Công Bố',
      url: '/announced-information-management',
    },
    {
      name:
        !!announcedInformationID || !!paramsURL.id
          ? 'Chỉnh sửa Thông Tin Công Bố'
          : 'Tạo mới Thông Tin Công Bố',
      url:
        !!announcedInformationID || !!paramsURL.id
          ? `/announced-information-management/edit/${paramsURL.id || announcedInformationID}`
          : '/announced-information-management/add',
    },
  ];

  const getDetaiAnnouncement = async (id: any) => {
    setLoading(true);
    try {
      const res = await dispatch(getAnnouncementByIdAsync(id));
      if (res?.payload?.status === 'Success') {
        setAnnouncementDetail(res?.payload?.data);
        setValue('title', res?.payload?.data?.title);
        setValue('title_eng', res?.payload?.data?.title_eng);
        setValue('description', res?.payload?.data?.description);
        setValue('description_eng', res?.payload?.data?.description_eng);
        setProvider({
          value: res?.payload?.data?.provider,
          label: res?.payload?.data?.provider,
        });
        setAnnouncementType({
          value: res?.payload?.data?.announcement_type,
          label: announcementCase(res?.payload?.data?.announcement_type),
        });
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error: any) {
      error.response.data.forEach((item: any) => {
        toast.error(item?.error_message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    }
    setLoading(false);
  };

  const announcementCase = (type: string) => {
    switch (type) {
      case 'FinancialReport':
        return 'Báo cáo tài chính';
      case 'Announcement':
        return 'Công bố thông tin';
      case 'CompanyDocument':
        return 'Tài liệu công ty';
      case 'DocumentReport':
        return 'Tài liệu báo cáo';
      case 'SbbsReport':
        return 'Thông báo';
      case 'MarginSecurity':
        return 'Giao Dịch Ký Quỹ';
      default:
        return '-';
    }
  };

  const submiAddAnnouncedtHandler: SubmitHandler<any> = async value => {
    if (paramsURL.id || announcedInformationID) {
      setSubmitUpdate(true);
      let params = {
        id: paramsURL.id || announcedInformationID,
        title: value.title,
        description: value.description,
        provider: provider?.value,
        announcement_type: announcementType?.value,
        title_eng: value.title_eng,
        description_eng: value.description_eng,
      };

      const res = await dispatch(updateAnnouncementAsync(params));
      if (res?.payload?.status === 'Success') {
        toast.success('Cập nhật thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitUpdate(false);
        setConfirmUpdateModal(false);
        navigate(
          `/announced-information-management/edit/${paramsURL.id || announcedInformationID}`
        );
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitUpdate(false);
        setConfirmUpdateModal(false);
      }
    }

    if (!paramsURL.id && !announcedInformationID) {
      setSubmitAdd(true);
      if (!provider?.value) {
        setError('provider', {
          type: 'manual',
          message: 'Vui lòng chọn nhà cung cấp',
        });
        toast.error('Vui lòng chọn nhà cung cấp!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
        return;
      }

      if (!announcementType?.value) {
        setError('announcement_type', {
          type: 'manual',
          message: 'Vui lòng chọn loại thông báo',
        });
        toast.error('Vui lòng chọn loại thông báo!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
        return;
      }

      let params = {
        title: value.title,
        description: value.description,
        provider: provider?.value,
        announcement_type: announcementType?.value,
        title_eng: value.title_eng,
        description_eng: value.description_eng,
      };

      const res = await dispatch(createAnnouncementAsync(params));
      if (res?.payload?.status === 'Success') {
        toast.success('Thêm mới thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
        setAnnouncedInformationID(res?.payload?.data?.id);
        navigate(`/announced-information-management/edit/${res?.payload?.data?.id}`);
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
      }
    }
  };

  const submitAddFileHandle = async () => {
    if (!currentFile?.target?.files[0]) {
      toast.error('Vui lòng chọn tài liệu đính kèm!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    if (!displayName) {
      toast.error('Vui lòng nhập tên hiển thị!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    setSubmitAdd(true);
    const file = currentFile?.target?.files[0];
    const paramsGetUrlUpload = {
      file_type:
        announcementDetail?.announcement_type === 'SbbsReport' ? 'SbbsReport' : 'Announcement',
    };
    const resLinkStore = await dispatch(getUrlUploadFilesAsync(paramsGetUrlUpload));
    if (resLinkStore?.payload?.status === 'Success') {
      const file_path = resLinkStore?.payload?.data?.file_path;
      let urlUpload = resLinkStore?.payload?.data?.url;
      if (urlUpload) {
        const res = await fetch(urlUpload, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file?.type,
          },
        });
        if (res.status === 200) {
          let paramsCreateFile = {
            id: paramsURL.id || announcedInformationID,
            file: file_path,
            display_name: displayName,
          };
          try {
            const resCreateFile = await dispatch(createFileAsync(paramsCreateFile));
            if (resCreateFile?.payload?.status === 'Success') {
              toast.success('Thêm mới tài liệu thành công!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setReloadPage(!reloadPage);
            } else {
              toast.error((resCreateFile?.payload?.error_message).split('|')[1], {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error: any) {
            error.response.data.forEach((item: any) => {
              toast.error(item?.error_message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            });
          }
        } else {
          toast.error('Có lỗi, vui lòng kiểm tra lại!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      toast.error('Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    setSubmitAdd(false);
    setShowPopupAddFile(false);
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      for (const key in errors) {
        const messageError = `${errors[key].message}`;
        toast.error(messageError || 'Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitAdd(false);
        setConfirmAddModal(false);
        setSubmitUpdate(false);
        setConfirmUpdateModal(false);
      }
    }
  };

  const handleAnnouncementTypeChange = (selectedOption: any) => {
    setAnnouncementType(selectedOption);
    clearErrors('announcement_type');
  };

  const handleProviderChange = (selectedOption: any) => {
    setProvider(selectedOption);
    clearErrors('provider');
  };

  useEffect(() => {
    if (paramsURL.id || announcedInformationID) {
      getDetaiAnnouncement(paramsURL.id || announcedInformationID);
    }
  }, [paramsURL.id, reloadPage, announcedInformationID]);

  const handleDeleteFileUpload = async (announcement_id: any, file_id: any) => {
    setConfirmDeleteFile(true);
    const params = {
      announcement_id,
      file_id,
    };
    const res = await dispatch(deleteFileAsync(params));
    if (res?.payload?.status === 'Success') {
      toast.success('Xóa tài liệu thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setShowPopupDeleteFile(false);
      setConfirmDeleteFile(false);
      setReloadPage(!reloadPage);
    } else {
      toast.error('Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setShowPopupDeleteFile(false);
      setConfirmDeleteFile(false);
    }
  };

  useEffect(() => {
    if (FileDelete?.file_url) {
      setIsEditFile(false);
    }
  }, [FileDelete]);

  const submitEditFileHandle = async () => {
    setSubmitUpdate(true);
    if (!currentFile || currentFile?.length === 0) {
      const params = {
        announcement_id: paramsURL.id || announcedInformationID,
        file_id: FileDelete?.id,
        file: FileDelete?.file,
        display_name: displayName,
      };

      const res = await dispatch(updateFileAsync(params));
      if (res?.payload?.status === 'Success') {
        toast.success('Cập nhật tài liệu thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setReloadPage(!reloadPage);
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      const file = currentFile?.target?.files[0];
      const paramsGetUrlUpload = {
        file_type:
          announcementDetail?.announcement_type === 'SbbsReport' ? 'SbbsReport' : 'Announcement',
      };
      const resLinkStore = await dispatch(getUrlUploadFilesAsync(paramsGetUrlUpload));
      if (resLinkStore?.payload?.status === 'Success') {
        const file_path = resLinkStore?.payload?.data?.file_path;
        let urlUpload = resLinkStore?.payload?.data?.url;
        if (urlUpload) {
          const res = await fetch(urlUpload, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file?.type,
            },
          });
          if (res.status === 200) {
            let paramsEditFile = {
              announcement_id: paramsURL.id || announcedInformationID,
              file_id: FileDelete?.id,
              file: file_path,
              display_name: displayName,
            };
            const resUpdateFile = await dispatch(updateFileAsync(paramsEditFile));

            if (resUpdateFile?.payload?.status === 'Success') {
              toast.success('Chỉnh sửa tài liệu thành công!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setReloadPage(!reloadPage);
            }
          } else {
            toast.error('Có lỗi, vui lòng kiểm tra lại!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } else {
          toast.error('Có lỗi, vui lòng kiểm tra lại!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        toast.error('Có lỗi, vui lòng kiểm tra lại!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    setShowPopupEditFile(false);
    setSubmitUpdate(false);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <>
          <Breadcrumb crumbs={crumbs} selected={selected} />
          <TitlePage
            icon={() => <AiOutlineBook />}
            name={`${
              !!paramsURL.id || !!announcedInformationID ? 'Chỉnh sửa' : 'Tạo mới'
            } Thông Tin Công Bố`}
          />
          <div className="mt-10">
            <form
              className="form"
              onSubmit={handleSubmit(submiAddAnnouncedtHandler, onError)}
              noValidate
              autoComplete="off"
            >
              <div className="flex gap-3">
                <ArticleCard
                  className={`${
                    paramsURL?.id &&
                    announcementDetail?.files &&
                    announcementDetail?.files?.length > 0
                      ? 'w-1/2'
                      : 'w-full'
                  }`}
                >
                  <div className="form-group">
                    <label htmlFor="title" className="required-before">
                      Tiêu đề
                    </label>
                    <input
                      id="title"
                      type="text"
                      autoComplete="title"
                      placeholder="Nhập tiêu đề"
                      className="form-control"
                      {...register('title', {
                        required: 'Vui lòng nhập tiêu đề',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title');
                        },
                        // value: categorysData?.title,
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.title?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="title_eng" className="required-before">
                      Tiêu đề (EN)
                    </label>
                    <input
                      id="title_eng"
                      type="text"
                      autoComplete="title_eng"
                      placeholder="Nhập tiêu đề (EN)"
                      className="form-control"
                      {...register('title_eng', {
                        required: 'Vui lòng nhập tiêu đề (EN)',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title_eng');
                        },
                        // value: categorysData?.title_eng,
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.title_eng?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description" className={`form-label`}>
                      Mô tả
                    </label>
                    <textarea
                      id="description"
                      placeholder="Nhập mô tả"
                      className="form-area"
                      required
                      rows={3}
                      cols={50}
                      {...register('description', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('description');
                        },
                        // value: categorysData?.description,
                      })}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description_eng" className={`form-label`}>
                      Mô tả (EN)
                    </label>
                    <textarea
                      id="description_eng"
                      placeholder="Nhập mô tả (EN)"
                      className="form-area"
                      required
                      rows={3}
                      cols={50}
                      {...register('description_eng', {
                        required: false,
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('description_eng');
                        },
                        // value: categorysData?.description_eng,
                      })}
                    ></textarea>
                  </div>
                  <div className="form-group flex gap-5">
                    <div className="w-1/2">
                      <label htmlFor="provider" className={`form-label required-before`}>
                        Nhà cung cấp
                      </label>
                      <Select
                        options={[
                          {
                            value: 'SBBS',
                            label: 'SBBS',
                          },
                        ]}
                        placeholder="Choose parent code"
                        value={provider}
                        onChange={handleProviderChange}
                        classNamePrefix={'select'}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.provider?.message as string}
                      </span>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="announcement_type" className={`form-label required-before`}>
                        Loại thông báo
                      </label>
                      <Select
                        options={announcementTypeOptions}
                        placeholder="Choose parent code"
                        value={announcementType}
                        onChange={handleAnnouncementTypeChange}
                        classNamePrefix={'select'}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.announcement_type?.message as string}
                      </span>
                    </div>
                  </div>
                </ArticleCard>
                {(paramsURL?.id || announcedInformationID) &&
                  announcementDetail?.files &&
                  announcementDetail?.files?.length > 0 && (
                    <ArticleCard className="w-1/2">
                      {announcementDetail?.files?.map((item: any) => {
                        return (
                          <div key={item?.id} className="relative">
                            <div className="flex gap-5">
                              <div className="form-group w-full">
                                <label htmlFor="display_name">Tên hiển thị</label>
                                <input
                                  id="display_name"
                                  type="text"
                                  autoComplete="display_name"
                                  placeholder="Nhập tên danh mục"
                                  className="form-control !pr-[70px] whitespace-nowrap block overflow-hidden text-ellipsis"
                                  disabled
                                  value={item?.display_name}
                                />
                              </div>
                            </div>
                            <div
                              className="absolute p-3 right-0 top-[29px] z-40 cursor-pointer w-fit"
                              onClick={() => {
                                setFileDelete(item);
                                setShowPopupDeleteFile(true);
                              }}
                            >
                              <BiTrash className="text-red-600 text-xl" />
                            </div>
                            <div
                              className="absolute p-3 right-[30px] top-[29px] z-40 cursor-pointer w-fit"
                              onClick={() => {
                                setShowPopupEditFile(true);
                                setFileDelete(item);
                                setDisplayName(item?.display_name);
                              }}
                            >
                              <BiEdit className="text-primary text-xl" />
                            </div>
                          </div>
                        );
                      })}
                    </ArticleCard>
                  )}
              </div>

              <div className={`flex mt-2 gap-5`}>
                {paramsURL.id || announcedInformationID ? (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmUpdateModal(true);
                    }}
                  >
                    Lưu
                  </button>
                ) : (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmAddModal(true);
                    }}
                  >
                    Lưu
                  </button>
                )}
                {(paramsURL.id || announcedInformationID) && (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={e => {
                      setShowPopupAddFile(true);
                      setDisplayName('');
                      setCurrentFile(null);
                    }}
                  >
                    Thêm tài liệu đính kèm
                  </button>
                )}
              </div>
              <Modal
                show={confirmAddModal}
                title={'Xác Nhận Tạo Mới'}
                onClose={() => {
                  setConfirmAddModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmAddModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitAdd}
                    onClick={handleSubmit(submiAddAnnouncedtHandler, onError)}
                  >
                    {submitAdd && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
              <Modal
                show={confirmUpdateModal}
                title={'Xác Nhận Chỉnh Sửa'}
                onClose={() => {
                  setConfirmUpdateModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmUpdateModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitUpdate"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitUpdate}
                    onClick={handleSubmit(submiAddAnnouncedtHandler, onError)}
                  >
                    {submitUpdate && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
              <Modal
                show={showPopupDeleteFile}
                title={'Xác Nhận Xóa Tài Liệu Đính Kèm'}
                onClose={() => {
                  setShowPopupDeleteFile(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setShowPopupDeleteFile(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={confirmDeleteFile}
                    onClick={() => {
                      handleDeleteFileUpload(
                        paramsURL.id || announcedInformationID,
                        FileDelete?.id
                      );
                    }}
                  >
                    {confirmDeleteFile && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
            </form>
            <>
              <Modal
                show={showPopupAddFile}
                title={'Thêm tài liệu đính kèm'}
                onClose={() => {}}
                className="max-w-[1000px]"
              >
                <div className="space-y-4 max-h-[400px] overflow-y-auto">
                  <ArticleCard className="relative">
                    <div className="form-group">
                      <label htmlFor={`file`} className={`form-label required-before`}>
                        Tài liệu đính kèm
                      </label>
                      <input
                        id={`file`}
                        type="file"
                        autoComplete={`file`}
                        placeholder="Nhập tên danh mục"
                        className="form-control !border-none !shadow-none !w-fit"
                        onChange={(e: any) => {
                          setCurrentFile(e);
                        }}
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="form-group w-full">
                        <label htmlFor={`display_name`} className={`form-label required-before`}>
                          Tên hiển thị
                        </label>
                        <input
                          id={`display_name`}
                          type="text"
                          autoComplete={`display_name`}
                          placeholder="Nhập tên hiển thị"
                          className="form-control"
                          onChange={e => {
                            setDisplayName(e.target.value);
                          }}
                          value={displayName}
                        />
                      </div>
                    </div>
                  </ArticleCard>
                </div>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setShowPopupAddFile(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitAdd"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitAdd}
                    onClick={() => {
                      submitAddFileHandle();
                    }}
                  >
                    {submitAdd && <CircleSpin />}
                    Lưu
                  </button>
                </div>
              </Modal>
              <Modal
                show={showPopupEditFile}
                title={'Chỉnh sửa tài liệu đính kèm'}
                onClose={() => {}}
                className="max-w-[1000px]"
              >
                <div className="space-y-4 max-h-[400px] overflow-y-auto">
                  <ArticleCard className="relative">
                    <div className="form-group">
                      <label htmlFor={`file_edit`} className={`form-label`}>
                        Tài liệu đính kèm
                      </label>
                      {!isEditFile ? (
                        <a
                          href={FileDelete?.file_url}
                          target="_blank"
                          rel="noreferrer"
                          className="block text-primary"
                        >
                          {FileDelete?.display_name}
                        </a>
                      ) : (
                        <input
                          id={`file_edit`}
                          type="file"
                          autoComplete={`file_edit`}
                          placeholder="Nhập tên danh mục"
                          className="form-control !border-none !shadow-none !w-fit"
                          onChange={(e: any) => {
                            setCurrentFile(e);
                          }}
                        />
                      )}
                    </div>
                    <div className="flex gap-4">
                      <div className="form-group w-full">
                        <label
                          htmlFor={`display_name_edit`}
                          className={`form-label required-before`}
                        >
                          Tên hiển thị
                        </label>
                        <input
                          id={`display_name_edit`}
                          type="text"
                          placeholder="Nhập tên hiển thị"
                          className="form-control"
                          defaultValue={getValues('display_name')}
                          onChange={e => {
                            setDisplayName(e.target.value);
                          }}
                          value={displayName}
                        />
                      </div>
                    </div>
                  </ArticleCard>
                </div>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setShowPopupEditFile(false);
                      setValue('file_edit', '');
                      setFileDelete(null);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitAdd"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitUpdate}
                    onClick={() => {
                      submitEditFileHandle();
                    }}
                  >
                    {submitUpdate && <CircleSpin />}
                    Lưu
                  </button>
                  <button
                    className="flex justify-center w-full md:w-52 form-button"
                    onClick={() => {
                      setIsEditFile(true);
                    }}
                  >
                    Thay đổi tài liệu đính kèm
                  </button>
                </div>
              </Modal>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default AnnouncedInformationAdd;
