import React from 'react';
import ProfileDropDown from 'components/ProfileDropDown';
import SideNavToggle from 'components/UiComponents/SideNavToggle';

const Header = () => {
  return (
    <div className="relative z-10 shadow-xs bg-white min-h-[50px] xl:min-h-[66px] flex items-center justify-between px-6 lg:px-8">
      <SideNavToggle />
      <ProfileDropDown />
    </div>
  );
};

export default Header;
