import React, { useState } from 'react';
import { useAuth } from 'contexts/Auth/AuthContext';
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineSetting } from 'react-icons/ai';
// import { GoPasskeyFill } from 'react-icons/go';
import { Link } from 'react-router-dom';
// import ChangePassword from 'components/ProfileDropDown/components/ChangePassword';
import { FaUser } from 'react-icons/fa';
import useComponentVisible from 'hooks/use-component-visible';

const ProfileDropDown = () => {
  const { user, logout } = useAuth();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  // const [isShowChangePassword, setIsShowChangePassword] = useState(false);

  return (
    <>
      <div className="relative" ref={ref}>
        <button className="block" onClick={() => setIsComponentVisible(o => !o)}>
          {user?.avatar ? (
            <div
              className={`background-contain rounded-full w-[40px] xl:w-[48px] h-[40px] xl:h-[48px]`}
              style={{
                backgroundImage: `url("${user?.avatar}")`,
              }}
            ></div>
          ) : (
            <div
              className={
                'w-[40px] xl:w-[48px] h-[40px] xl:h-[48px] rounded-full bg-[#E7EDF4] flex items-center justify-center overflow-hidden'
              }
            >
              <FaUser size={40} color="#7EA0B7" className="-mb-2" />
            </div>
          )}
        </button>
        {isComponentVisible && (
          <div className="w-[280px] absolute z-10 bg-white  rounded-md top-[66px] right-[30px] bg-white drop-shadow-md">
            <div>
              <div className="w-full grid drop-shadow-md">
                <div className="flex flex-col justify-center p-4 gap-1 h-[80px] bg-primary-main text-white rounded">
                  <div className="text-bold">{user?.fullname}</div>
                  <div className="text-sm">{user?.email}</div>
                </div>
                <div className="py-2">
                  <Link
                    to="/setting-system"
                    onClick={() => setIsComponentVisible(false)}
                    className="flex gap-x-2 items-center px-4 py-[10px] rounded-sm hover:bg-primary-main hover:text-white"
                  >
                    <AiOutlineSetting />
                    <span className="text-sm">Cài đặt hệ thống</span>
                  </Link>
                  {/* <button
                    type="button"
                    className="flex gap-x-2 items-center px-4 py-[10px] rounded-sm hover:bg-primary-main hover:text-white w-full"
                    onClick={() => {
                      setIsShowChangePassword(true);
                      setIsComponentVisible(false);
                    }}
                  >
                    <GoPasskeyFill />
                    <span className="text-sm">Cập nhật mật khẩu</span>
                  </button> */}
                  <button
                    type="button"
                    className="flex gap-x-2 items-center px-4 py-[10px] rounded-sm hover:bg-primary-main hover:text-white w-full"
                    onClick={() => logout()}
                  >
                    <BiLogOut />
                    <span className="text-sm"> Thoát tài khoản</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <ChangePassword
        isOpen={isShowChangePassword}
        closeModal={() => setIsShowChangePassword(false)}
      /> */}
    </>
  );
};

export default ProfileDropDown;
