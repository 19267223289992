import React, { useState, ReactNode, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import jwt from 'jwt-decode';
import AuthContext from './AuthContext';
import { UserType } from 'types/User.type';
import { NormalResponseError } from 'types/Common.type';
import AuthService from 'services/auth.service';
import axiosInstance from 'services/restful.service';
import { ArticleFilterParamsType } from 'types/data.type';
import { usePermissions } from 'hooks';
import { PERMISSIONS } from 'constant';

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const { groupPermissions } = usePermissions();
  const [user, setUser] = useState<UserType>();
  const [error, setError] = useState<NormalResponseError>();
  const [loading, setLoading] = useState(false);

  const [nextUrlCache, setNextUrlCache] = useState<string>();
  const [isShouldBlockNavigate, setIsShouldBlockNavigate] = useState(false);
  const [isShowConfirmSaveData, setIsShowConfirmSaveData] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const navigate = useNavigate();
  const [articleFilterParams, setArticleFilterParams] = useState<ArticleFilterParamsType>({
    category_id: '5abaa8ae-e9c9-465d-acfd-b77007158a10',
    article_type: 0,
    page_size: 20,
    page_index: 1,
    sort_by: 'published_date',
    sort_order: 1,
  });
  const location = useLocation();

  const axiosInstanceIntceptorWithCookie = (config: AxiosRequestConfig) => {
    const newHeaders = { ...config };
    return newHeaders;
  };

  const updateNextUrlCache = (value?: string) => {
    if (value) {
      setNextUrlCache(value);
    } else {
      setNextUrlCache(undefined);
    }
  };

  const updateIsShouldBlockNavigate = (value: boolean) => {
    setIsShouldBlockNavigate(value);
  };

  const updateIsShowConfirmSaveData = (value: boolean) => {
    setIsShowConfirmSaveData(value);
  };

  const SaveArticleFilterToContext = (data: ArticleFilterParamsType) => {
    for (const key in data) {
      if (
        Object.prototype.hasOwnProperty.call(data, key) &&
        (data[key] === undefined || data[key] === '')
      ) {
        delete data[key];
      }
    }
    setArticleFilterParams(data);
  };

  useEffect(() => {
    if (error) setError(undefined);

    let user: any = localStorage.getItem('user');

    const currentDate = new Date();
    let checkExp = true;
    if (user) {
      const parseUser = JSON.parse(user as string);
      if (parseUser?.exp * 1000 < currentDate.getTime()) {
        checkExp = false;
      } else if (parseUser?.exp * 1000 - currentDate.getTime() < 1200000) {
        checkExp = true;
      } else {
        checkExp = true;
      }
    }

    if (user && location?.pathname !== '/ms-callback' && checkExp) {
      setUser(JSON.parse(user));
      setLoadingInitial(false);
      return;
    } else if (location?.pathname === '/ms-callback') {
      setLoadingInitial(false);
      return;
    } else {
      navigate('/login');
      setLoadingInitial(false);
      return;
    }
  }, [location.pathname]);

  React.useEffect(() => {
    let idInteceptor;
    if (user?.email) {
      idInteceptor = axiosInstance.interceptors.request.use(
        axiosInstanceIntceptorWithCookie,
        function (error) {
          return Promise.reject(error);
        }
      );
    } else {
      if (idInteceptor) {
        axiosInstance.interceptors.request.eject(idInteceptor);
      }
    }
    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const { data, status } = error.response;
        if (
          status === 401
          // || localStorage.getItem("user") &&
          // (data?.error?.name === AUTH_CONST.TOKEN_EXPIRED ||
          // data?.error?.name === AUTH_CONST.UNAUTHORIZED)
        ) {
          logout();
        }
        if (status === 404) {
          navigate('/404');
        }
        if (status === 403) {
          navigate('/forbidden');
        }
        if (status === 500) {
          navigate('/500');
        }
        // if (
        //   status === 403 &&
        //   data?.error?.name === AUTH_CONST.UNAUTHORIZED) {
        //     setError(data);
        // }
        // if (
        //   status === 403 &&
        //   data?.error?.name === AUTH_CONST.FORBIDDEN) {
        //     navigate('/forbidden');
        // }
        return Promise.reject(error);
      }
    );
  }, [user?.email]);

  const login = (username: string, password: string) => {
    setLoading(true);
    setError(undefined);
    AuthService.login({ username, password })
      .then(res => {
        if (!res?.data?.access_token) {
          setError({
            error: {
              message: 'Username or Password chưa đúng.',
            },
          });
        }
        const userInfo: any = jwt(res?.data?.access_token);

        const user = {
          ...userInfo,
          access_token: res?.data?.access_token,
        };
        setUser({
          ...userInfo,
          access_token: user.access_token,
        });
        const permissions = groupPermissions(userInfo?.permission);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
        navigate('/');
      })
      .catch(error => {
        setError({ error });
      })
      .finally(() => setLoading(false));
  };

  const loginMS = (token: string) => {
    setLoading(true);
    setError(undefined);
    AuthService.loginMS(token)
      .then(res => {
        if (!res?.data?.access_token) {
          setError({
            error: {
              message: 'Tài khoản không có quyền đăng nhập!',
            },
          });
        } else {
          const userInfo: any = jwt(res?.data?.access_token);

          const user = {
            ...userInfo,
            access_token: res?.data?.access_token,
          };
          setUser({
            ...userInfo,
            access_token: user.access_token,
          });

          const permissions = groupPermissions(userInfo?.permission);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
          navigate('/');
        }
      })
      .catch(error => {
        setError({ error });
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    AuthService.logout();
    navigate('/login');
    localStorage.clear();
    sessionStorage.clear();
    setUser(undefined);
  };

  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      loginMS,
      logout,
      setError,
      articleFilterParams,
      SaveArticleFilterToContext,
      isShouldBlockNavigate,
      updateIsShouldBlockNavigate,
      isShowConfirmSaveData,
      updateIsShowConfirmSaveData,
      nextUrlCache,
      updateNextUrlCache,
    }),
    [
      user,
      loading,
      error,
      articleFilterParams,
      SaveArticleFilterToContext,
      isShouldBlockNavigate,
      updateIsShouldBlockNavigate,
      isShowConfirmSaveData,
      updateIsShowConfirmSaveData,
      nextUrlCache,
      updateNextUrlCache,
    ]
  ) as any;

  return <AuthContext.Provider value={value}>{!loadingInitial && children}</AuthContext.Provider>;
};

export default AuthProvider;
