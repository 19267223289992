import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import AddUserManagement from 'pages/ReportResearchManagement/AddUserManagement';
import UserManagement from 'pages/ReportResearchManagement/UserManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const UserResearchManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" isBack={false} />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <UserManagement />
            </React.Suspense>
          }
        />
        <Route
          path="/add"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AddUserManagement />
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AddUserManagement />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default UserResearchManagementRoutes;
