import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { AiOutlineBook } from 'react-icons/ai';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { PaginationOwn } from 'components/Shared';
import { BiEdit, BiTrash } from 'react-icons/bi';
import Modal from 'components/UiComponents/Modal';
import { toast } from 'react-toastify';
import { getBannerList, deleteBanner } from 'services/banner.service';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { PERMISSIONS_KEY } from 'constant';
import { PERMISSIONS_ACTIONS } from 'hooks';

const BannerManagement = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState<any>();
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const [idAnnouncement, setIdAnnouncement] = useState<any>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [data, setData] = useState({
    items: [],
    total_results: 0,
  });

  const [crumbs] = useState([
    {
      name: 'Quản Lý Banner',
      url: '/banner-management',
    },
  ]);

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    const res = await getBannerList(params);
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params: any = {
        page_index: page,
        page_size: itemPerPage,
      };
      if (keyword) {
        params = {
          ...params,
          Keyword: keyword,
        };
      }
      getData(params);
    }
  }, [itemPerPage, page, reloadPage]);

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const onSearch = (reset?: boolean) => {
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/banner-management`);
    setItemPerPage(value);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/banner-management?page=${event}`);
  }, []);

  const handleDeleteItem = async (id: any) => {
    setSubmitDelete(true);
    const res = await deleteBanner(id);
    if (res?.status === 'Success') {
      setSubmitDelete(false);
      setConfirmDeleteModal(false);
      setReloadPage(!reloadPage);
      toast.success('Xóa banner thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setSubmitDelete(false);
      setConfirmDeleteModal(false);
      toast.error('Xóa banner thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage icon={() => <AiOutlineBook />} name="Quản Lý Banner" />
        <PermissionsGate name={PERMISSIONS_KEY.ManageBanners} action={PERMISSIONS_ACTIONS.CREATE}>
          <Link to={'/banner-management/add'}>
            <span className="flex justify-center w-full md:w-52 form-button">Tạo Banner Mới</span>
          </Link>
        </PermissionsGate>
      </div>
      <div className="mt-10">
        <div className="flex">
          <div className="flex  w-1/2 space-x-2">
            <SearchButton
              onSearch={onSearch}
              keyword={keyword}
              setKeyword={setKeyword}
              _handleKeyDown={_handleKeyDown}
            />
          </div>
          <div className="ml-auto mt-2">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th style={{ width: '60px' }}>STT</th>
                <th style={{ width: '80px' }}>Thứ tự hiển thị</th>
                <th>
                  <span>Tiêu đề</span>
                </th>
                <th>
                  <span>Nội dung</span>
                </th>
                <th>
                  <span>CTA</span>
                </th>
                <th>
                  <span>Link</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {data?.items?.map((item: any, index: number) => (
                  <tr key={item?.id}>
                    <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                    <td>{item?.index}</td>
                    <td className="font-semibold max-w-[150px] overflow-scroll hidden-scrollbar">
                      <div className="w-[300px]">
                        <span>{item?.title || '-'}</span>
                      </div>
                    </td>
                    <td>
                      <span>{item?.content || '-'}</span>
                    </td>
                    <td>{item?.button_text || '-'}</td>
                    <td>
                      <span>{item?.button_url || '-'}</span>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageBanners}
                          action={PERMISSIONS_ACTIONS.UPDATE}
                        >
                          <div className="items-center text-base table-action-btn">
                            <Link to={`/banner-management/edit/${item?.id}`}>
                              <BiEdit className="text-primary text-lg" />
                            </Link>
                          </div>
                        </PermissionsGate>
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageBanners}
                          action={PERMISSIONS_ACTIONS.DELETE}
                        >
                          <div className="flex gap-3 items-center text-base table-action-btn">
                            <div
                              onClick={() => {
                                setIdAnnouncement(item?.id);
                                setConfirmDeleteModal(true);
                              }}
                            >
                              <BiTrash className="text-red-main text-lg" />
                            </div>
                          </div>
                        </PermissionsGate>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {data?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No announcement found</p>
        )}
        {data?.total_results > itemPerPage && !loading && (
          <div className="my-6 flex text-right">
            <PaginationOwn
              totalItems={data?.total_results}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
      <Modal
        show={confirmDeleteModal}
        title={'Xác Nhận Xoá'}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={() => {
              handleDeleteItem(idAnnouncement);
            }}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BannerManagement;
