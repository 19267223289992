import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer/Footer';
export interface MasterLayoutProps {
  type: string;
  isBack?: boolean;
}

const MasterLayout: React.FC<MasterLayoutProps> = () => {
  return (
    <>
      <Header />
      <main
        className={`${styles['masterlayoutside-main']} mt-0 px-2 py-2 xl:px-8 xl:py-4 bg-primary-c3 bg-opacity-20`}
      >
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default MasterLayout;
