import React, { useEffect } from 'react';
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectThemeConfig, toggleShowSidebar } from 'store/slices/ThemeConfigSlice';

const SideNavToggle = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector(selectThemeConfig);

  const onCollapse = () => {
    dispatch(toggleShowSidebar(!theme?.isShowSidebar));
  };

  const handleScreenSize = () => {
    if (window.innerWidth < 1200) {
      dispatch(toggleShowSidebar(false));
    }
  };

  useEffect(() => {
    handleScreenSize();
    window.addEventListener('resize', handleScreenSize); // thêm sự kiện resize để theo dõi sự thay đổi kích thước màn hình
    return () => {
      window.removeEventListener('resize', handleScreenSize); // loại bỏ sự kiện resize khi component bị unmount
    };
  }, []);

  return (
    <button
      onClick={onCollapse}
      className="text-2xl header-action-item-hoverable header-action-item hover:bg-gray-e0 px-1 py-1"
    >
      {theme?.isShowSidebar ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 />}
    </button>
  );
};

export default SideNavToggle;
