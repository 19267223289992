import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import AddReportResearchManagement from 'pages/ReportResearchManagement/AddReportResearchManagement';
import ReportResearchManagement from 'pages/ReportResearchManagement/ReportResearchManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const ReportResearchManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" isBack={false} />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ReportResearchManagement />
            </React.Suspense>
          }
        />
        <Route
          path="/add"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AddReportResearchManagement />
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AddReportResearchManagement />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default ReportResearchManagementRoutes;
