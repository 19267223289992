import React from 'react';
import UserInfor from 'components/UserInfor';
import { NavBar } from 'components/NavBar';
import styles from './index.module.scss';
import sbbs_logo from '../../assets/images/sbbs_logo.png';

const SideBar: React.FC = () => {
  return (
    <>
      <div className="py-[18px] px-7">
        <img className="h-[30px] w-auto" src={sbbs_logo} alt="SBBS" />
      </div>
      <div className={styles['page-sidebar']}>
        <UserInfor />
        <NavBar />
      </div>
    </>
  );
};

export default SideBar;
