import React, { ReactNode } from 'react';
import { ErrorMessage as ErrorMessageDefault } from '@hookform/error-message';

type Props = {
  name: string;
  icon?: ReactNode;
  classname?: string;
};

export const ErrorMessage = ({ name, className, icon, ...rest }: Props & any) => {
  return (
    <ErrorMessageDefault
      {...rest}
      name={name}
      render={({ message }) => (
        <div
          className={`flex items-center gap-x-2 text-red-main text-xs${
            className ? ' ' + className : ''
          }`}
        >
          {icon ? icon : null}
          <span>{message}</span>
        </div>
      )}
    />
  );
};
