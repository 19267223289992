import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface ThemeConfigState {
  isShowSidebar: boolean;
}

const initialState: ThemeConfigState = {
  isShowSidebar: true,
};

export const ThemeConfigSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleShowSidebar: (state, action) => {
      state.isShowSidebar = action.payload;
    },
  },
});

export const { toggleShowSidebar } = ThemeConfigSlice.actions;
export const selectThemeConfig = (state: RootState) => state;

export default ThemeConfigSlice.reducer;
