import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectThemeConfig } from 'store/slices/ThemeConfigSlice';
import Sidebar from '../Sidebar';

type ContainerLayoutProps = {
  children: React.ReactNode;
};

const ContainerLayout = ({ children }: ContainerLayoutProps) => {
  const { theme } = useAppSelector(selectThemeConfig);
  return (
    <div className="min-h-screen">
      <div
        className={`fixed z-10 min-h-full left-0 top-0 w-[248px] bg-green-900 overflow-hidden transition-[translate] ${
          theme?.isShowSidebar ? 'translate-x-0' : 'translate-x-[-248px]'
        }`}
      >
        <Sidebar />
      </div>
      <div className={`transition-[padding] ${theme?.isShowSidebar ? 'pl-[248px]' : 'pl-0'}`}>
        {children}
      </div>
    </div>
  );
};

export default ContainerLayout;
