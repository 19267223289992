import React from 'react';
import { NavBarItem } from './NavBarItem';
import { PERMISSIONS_KEY } from 'constant';
import { IMenu } from 'types';

const MENU: IMenu[] = [
  {
    title: 'DASHBOARD',
    url: '/',
    name: PERMISSIONS_KEY.Dashboard,
  },
  {
    title: 'QUẢN LÝ TÀI CHÍNH',
    url: '',
    submenu: [
      {
        title: 'Quản Lý Tiền',
        url: '',
        name: PERMISSIONS_KEY.ManageCash,
        submenu: [
          { title: 'Nạp tiền', url: '/deposit-management', name: PERMISSIONS_KEY.ManageDeposit },
          {
            title: 'Rút tiền',
            url: '/withdraw-management',
            name: PERMISSIONS_KEY.ManageWithdrawal,
          },
          {
            title: 'Giao dịch cần duyệt',
            url: '/approve-withdraw-management',
            name: PERMISSIONS_KEY.ManageTransaction,
          },
        ],
      },
      {
        title: 'Quản Lý File Đối Soát',
        url: '/tcb-report-files-management',
        name: PERMISSIONS_KEY.ManageReconciliationFile,
      },
    ],
  },
  {
    title: 'QUẢN LÝ TÀI KHOẢN',
    url: '',
    submenu: [
      {
        title: 'Quản Lý Tài Khoản',
        url: '/account-management',
        name: PERMISSIONS_KEY.ManageAccounts,
      },
      {
        title: 'Quản Lý Người Dùng',
        url: '/user-management',
        name: PERMISSIONS_KEY.ManageUsers,
      },
    ],
  },
  {
    title: 'QUẢN LÝ TÍNH NĂNG',
    url: '',
    submenu: [
      {
        title: 'Quản Lý Thông Tin Công Bố',
        url: '/announced-information-management',
        name: PERMISSIONS_KEY.AnnounceInformation,
      },
      {
        title: 'Quản Lý Banner',
        url: '/banner-management',
        name: PERMISSIONS_KEY.ManageBanners,
      },
      {
        title: 'FAQ',
        url: '_',
        name: PERMISSIONS_KEY.ManageFaq,
        submenu: [
          { title: 'Category', url: '/faq-category-management', name: PERMISSIONS_KEY.ManageFaq },
          { title: 'Bài viết', url: '/faq-article-management', name: PERMISSIONS_KEY.ManageFaq },
        ],
      },
      {
        title: 'Quản Mã Môi Giới',
        url: '/referral-code-management',
        name: PERMISSIONS_KEY.ManageReferralCode,
      },
      {
        title: 'Quản Lý Tin Tức Sự Kiện',
        url: '/news-management',
        name: PERMISSIONS_KEY.ManageNewEvents,
      },
      {
        title: 'Trung Tâm Phân Tích',
        url: '_',
        name: PERMISSIONS_KEY.ManageResearchCenter,
        submenu: [
          {
            title: 'Quản lý báo cáo',
            url: '/report-research-management',
            name: PERMISSIONS_KEY.ManageResearchCenter,
          },
          {
            title: 'Quản lý user',
            url: '/user-research-management',
            name: PERMISSIONS_KEY.ManageResearchCenter,
          },
        ],
      },
    ],
  },
  {
    title: 'QUẢN LÝ HỆ THỐNG',
    url: '',
    submenu: [
      {
        title: 'Vận Hành',
        url: '/de-management',
        name: PERMISSIONS_KEY.ManageDE,
      },
    ],
  },
];

export const NavBar: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 mt-2">
      <ul className="list-[upper-roman] list-inside text-white">
        {MENU.map((i, idx) => (
          <li className="p-4 font-bold text-xs">
            <span>{i.title}</span>
            {i.submenu ? (
              <ul className="font-normal text-sm menu-list mt-3 flex flex-col gap-1">
                {i.submenu.map((i, idx) => (
                  <NavBarItem key={`menu-${idx}`} item={i} />
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};
