export enum AFE_STATUS {
  Pending = 'Chờ duyệt',
  Approved = 'Duyệt',
  Rejected = 'Từ chối',
}

export enum TRANSACTION_STATUS {
  Init = 'Init',
  PendingApproval = 'PendingApproval',
  Transferring = 'Transferring',
  Success = 'Success',
  Failed = 'Failed',
  FailedObvious = 'FailedObvious',
  RefundProcessing = 'RefundProcessing',
  SuccessManual = 'SuccessManual',
  Cancel = 'Cancel',
}

export enum ACCOUNT_STATUS {
  Active = 'Active',
  Deactive = 'Lock',
  Deleted = 'Deleted',
}

export enum RESPONSIVE_STATUS {
  Success = 'Success',
}

export enum REPORT_TYPE {
  COMPANY_RESEARCH = 'Báo cáo phân tích công ty',
  MACROECONOMICS = 'Báo cáo vĩ mô',
  SECTOR_REPORT = 'Báo cáo ngành',
  STRATEGY = 'Báo cáo chiến lược',
  DAILY_NEWS = 'Tin tức ngày',
  TECHNICAL_ANALYSIS = 'Phân tích kỹ thuật',
}