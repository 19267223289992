import React from 'react';
import { UserType } from 'types/User.type';
import { NormalResponseError } from 'types/Common.type';
import { ArticleFilterParamsType } from 'types/data.type';

interface AuthContextType {
  user?: UserType;
  loading: boolean;
  error?: NormalResponseError;
  setError?: (payload: string | undefined) => void;
  login: (username: string, password: string) => void;
  loginMS: (token: string) => void;
  logout: () => void;
  articleFilterParams?: ArticleFilterParamsType;
  SaveArticleFilterToContext: (data: ArticleFilterParamsType) => void;
  isShouldBlockNavigate: boolean;
  updateIsShouldBlockNavigate: (value: boolean) => void;
  isShowConfirmSaveData: boolean;
  updateIsShowConfirmSaveData: (value: boolean) => void;
  nextUrlCache?: string;
  updateNextUrlCache: (value?: string) => void;
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthContext;
